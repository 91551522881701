<template>
  <summary-chart-holder
    :chart-loading="isFetchingTimeseries"
    :stats-loading="isFetchingTotals"
    class="SummaryAreaChartTotalsSection"
  >
    <template #chart>
      <slot name="chart" />
      <summary-area-chart
        :timeseries="timeseries"
        :type="type"
      />
    </template>
    <total-hypefactors-value-data-card
      slot="stat_1"
      :locale="locale"
      :total="totalHfValue.current"
      :percentage="totalHfValue.growth"
      :active="type === 'hfValue'"
      :class="{ 'is-cursor-pointer': canChangeType }"
      data-testid="SquareStat"
      class="is-full-height"
      @click.native="changeType('hfValue')"
    />
    <total-impressions-data-card
      slot="stat_2"
      :locale="locale"
      :active="type === 'impressions'"
      :total="totalImpressions.current"
      :percentage="totalImpressions.growth"
      :class="{ 'is-cursor-pointer': canChangeType }"
      data-testid="SquareStat"
      class="is-full-height"
      @click.native="changeType('impressions')"
    />
    <total-clippings-data-card
      slot="stat_3"
      :locale="locale"
      :active="type === 'clippings'"
      :total="totalClippings.current"
      :aplus="totalClippings.growth_aplus"
      :bplus="totalClippings.growth_bplus"
      :percentage="totalClippings.growth"
      :class="{ 'is-cursor-pointer': canChangeType }"
      data-testid="SquareStat"
      class="is-full-height"
      @click.native="changeType('clippings')"
    />
    <template slot="stat_4">
      <slot />
    </template>
  </summary-chart-holder>
</template>

<script>
import { totalsStatFactory } from '@hypefactors/shared/js/factories/metrics'

import SummaryAreaChart from './SummaryAreaChart'
import SummaryChartHolder from '../SummaryChartHolder'
import TotalClippingsDataCard from '@hypefactors/shared/js/components/squares/TotalClippingsDataCard'
import TotalImpressionsDataCard from '@hypefactors/shared/js/components/squares/TotalImpressionsDataCard'
import TotalHypefactorsValueDataCard from '@hypefactors/shared/js/components/squares/TotalHypefactorsValueDataCard'

/**
 * @module SummaryAreaChartTotalsSection
 * Component used both on Dashboard and Measure/Facts to display top combined chart and boxes
 */
export default {
  name: 'SummaryAreaChartTotalsSection',

  components: {
    SummaryAreaChart,
    SummaryChartHolder,
    TotalClippingsDataCard,
    TotalImpressionsDataCard,
    TotalHypefactorsValueDataCard
  },

  props: {
    type: {
      type: String,
      required: true
    },

    timeseries: {
      type: Array,
      default: () => ([]),
      required: true
    },

    isFetchingTotals: {
      type: Boolean,
      default: false
    },

    isFetchingTimeseries: {
      type: Boolean,
      default: false
    },

    canChangeType: {
      type: Boolean,
      default: true
    },

    totalHfValue: {
      type: Object,
      default: () => totalsStatFactory()
    },

    totalImpressions: {
      type: Object,
      default: () => totalsStatFactory()
    },

    totalClippings: {
      type: Object,
      default: () => totalsStatFactory()
    },
    locale: {
      type: String,
      required: false,
      default: null
    }
  },

  methods: {
    changeType (type) {
      if (!this.canChangeType) {
        return
      }

      // dont emit event if its already the same
      if (this.type === type) {
        return
      }

      this.$emit('update:type', type)
    }
  }
}
</script>
