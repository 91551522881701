<template>
  <data-card
    :title="$t('general.hypefactors_value', locale)"
    :size="size"
    v-bind="$attrs"
    class="TotalHypefactorsValueDataCard"
  >
    <template #default>
      <div
        v-html="total ? $moneyAbbrFormatHtml(total): $t('general.not_available', locale)"
      />
    </template>
    <template #footer>
      <data-percentage-change
        :percentage="percentage"
        :title="$t('general.compare_previous_period', locale)"
      />
    </template>
  </data-card>
</template>

<script>
import MetricsDataCardBase from './MetricsDataCardBase'

export default {
  name: 'TotalHypefactorsValueDataCard',
  extends: MetricsDataCardBase,
  props: {
    locale: {
      type: String,
      required: false,
      default: null
    }
  }
}
</script>
