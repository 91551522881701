<template>
  <div ref="chart" class="SummaryAreaChart chart" />
</template>
<script>
import AreaChart from '@hypefactors/shared/js/components/charts/base/AreaChart'
import { abbrTimeseriesFormatter, moneyTimeseriesFormatter } from '@hypefactors/shared/js/components/charts/TooltipFormatter'

/**
 * Chart sensitive to the 'type' property. On each type change, needs to get re-render.
 * Mainly used on the {@link module:SummaryAreaChartTotalsSection}
 * @module SummaryAreaChart
 */
export default {
  name: 'SummaryAreaChart',

  props: {
    type: {
      type: String,
      default: 'hfValue'
    },

    timeseries: {
      type: Array,
      required: true
    }
  },

  data () {
    return {
      $chart: null,
      types: {
        hfValue: this.$t('general.hypefactors_value'),
        impressions: this.$t('general.impressions'),
        clippings: this.$t('general.clippings')
      }
    }
  },

  watch: {
    timeseries: 'redrawChart'
  },

  mounted () {
    this.createChart()
  },

  beforeDestroy () {
    if (this.$chart && this.$chart.hasOwnProperty('destroy')) this.$chart.destroy()
  },

  methods: {
    createChart () {
      this.$chart = new AreaChart(
        this.$refs.chart,
        this.createSeries(),
        {
          title: { text: '' },
          tooltipFormatter: moneyTimeseriesFormatter,
          yAxisLabel: this.$currencySymbol(),
          yAxis: {
            gridLineColor: '#DEDEDE',
            title: {
              align: 'high',
              offset: 0,
              rotation: 0,
              y: -15,
              x: -10
            }
          },
          chart: {
            backgroundColor: '#ebebeb' // $hf__color-gray--light from SASS
          },
          legend: {
            layout: 'horizontal',
            align: 'right',
            verticalAlign: 'top',
            floating: false,
            margin: 30,
            y: -10,
            itemStyle: {
              fontWeight: 'normal'
            }
          }
        }
      )
    },

    async redrawChart () {
      await this.$nextTick()

      const chartInstance = this.$chart.instance
      this.$chart.update(this.createSeries())

      if (this.type !== 'hfValue') {
        const yAxis = chartInstance.yAxis[0].axisTitle
        yAxis && yAxis.hide()
        chartInstance.tooltip.options.formatter = abbrTimeseriesFormatter
      } else {
        chartInstance.yAxis[0].update({
          title: {
            text: this.$currencySymbol()
          }
        })
        chartInstance.tooltip.options.formatter = moneyTimeseriesFormatter
      }
    },

    createSeries () {
      const seriesArray = this.timeseries
      if (this.type !== 'hfValue') {
        return seriesArray
      }
      return seriesArray.map((value) => {
        return {
          name: value.name,
          data: value.data.map(([dataTime, dataValue]) => {
            return [dataTime, this.$store.getters.convertCurrency(dataValue)]
          })
        }
      })
    }

  }
}
</script>
