<template>
  <div
    class="DataPercentageChange"
    :class="[diff]"
  >
    <div
      v-if="diff"
      class="icon is-small m-r-s"
    >
      <VIcon :type="icon" fallback />
    </div>
    <template v-if="percentage && percentage <= 3">
      {{ percentageFormat(percentage) }}
    </template>
    <div v-else-if="percentage > 3" class="is-size-6">
      {{ $t('components.main_dashboard.square_percentage_change.growing_fast') }}
    </div>
  </div>
</template>

<script>
import VIcon from '@hypefactors/shared/js/components/core/VIcon'
import PercentageMixin from '@hypefactors/shared/js/mixins/PercentageMixin'

export default {
  name: 'DataPercentageChange',

  components: {
    VIcon
  },

  mixins: [PercentageMixin],

  props: {
    percentage: {
      type: Number,
      default: 0
    }
  },
  computed: {
    icon () {
      if (this.diff === 'inc') return 'caret-up'
      if (this.diff === 'dec') return 'caret-down'
      return ''
    },
    diff () {
      return this.percentage ? (this.percentage > 0 ? 'inc' : 'dec') : ''
    }
  }
}
</script>

<style lang="scss">
@import '~utils';

.DataPercentageChange {
  font-size: 1.5em;
  font-weight: 900;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
