import DataCard from '@hypefactors/shared/js/components/core/DataCard'

import DataPercentageChange from './DataPercentageChange'

export default {
  inheritAttrs: false,
  components: {
    DataCard,
    DataPercentageChange
  },
  props: {
    total: {
      type: [String, Number],
      required: true
    },
    percentage: {
      type: Number,
      default: 0
    },
    size: {
      type: String,
      default: 'medium'
    }
  }
}
