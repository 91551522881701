<template>
  <data-card
    :title="$t('general.impressions', locale)"
    :size="size"
    v-bind="$attrs"
    class="TotalImpressionsDataCard"
  >
    <template #default>
      <template v-if="total">
        {{ abbr(total) }}
      </template>
      <template v-else>
        {{ $t('general.not_available', locale) }}
      </template>
    </template>
    <template #footer>
      <data-percentage-change
        :percentage="percentage"
        :title="$t('general.compare_previous_period', locale)"
      />
    </template>
  </data-card>
</template>

<script>
import MetricsDataCardBase from './MetricsDataCardBase'
import AbbrMixin from '@hypefactors/shared/js/mixins/AbbrMixin'

export default {
  name: 'TotalImpressionsDataCard',

  extends: MetricsDataCardBase,

  mixins: [AbbrMixin],

  props: {
    locale: {
      type: String,
      required: false,
      default: null
    }
  }
}
</script>
