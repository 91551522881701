<template>
  <data-card
    :title="$t('general.clippings', locale)"
    :size="size"
    v-bind="$attrs"
    class="TotalClippingsDataCard"
  >
    <template #default>
      <template v-if="total">
        {{ total }}
      </template>
      <template v-else>
        {{ $t('general.not_available', locale) }}
      </template>
    </template>
    <template #footer>
      <data-percentage-change
        :percentage="percentage"
        :title="$t('general.compare_previous_period', locale)"
      />
    </template>
  </data-card>
</template>

<script>
import MetricsDataCardBase from './MetricsDataCardBase'

/**
 * Shows a data card with Clipping in
 * used on {@see module:SummaryAreaChartTotalsSection} and {@see module:DashboardBrandFactsBrandRow}
 * @module TotalClippingsDataCard
 */
export default {
  name: 'TotalClippingsDataCard',
  extends: MetricsDataCardBase,
  props: {
    aplus: {
      type: Number,
      default: 0
    },
    bplus: {
      type: Number,
      default: 0
    },
    locale: {
      type: String,
      required: false,
      default: null
    }
  }
}
</script>
